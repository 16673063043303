// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { SummaryTableData } from "@besc/ui";
import { PlanInfo, PlanDetails, PlanCoverageDetails, Rates, RateType } from "../interfaces";

const blankCell: SummaryTableData = {
    label: '',
    value: ''
};

const blankHeader: SummaryTableData = {
    label: '',
    value: '',
    isHeader: true,
};

export const getPlanDetailsInfo = (plan: {
    info: PlanInfo,
    details: PlanDetails,
    effectiveDate: string,
    expirationDate: string,
    editable: boolean
}): SummaryTableData[] => ([
    {
        label: 'Plan effective',
        value: plan.effectiveDate,
        action: plan.editable ? 'Edit' : '',
    },
    {
        label: '',
        value: ''
    },
    {
        label: 'Network',
        value: plan.info.networkName ?? '--',
    },
    {
        label: 'Type',
        value: plan.info.type,
    },
    {
        label: 'HSA Qualified',
        value: plan.info.hsaEligible ? 'Yes' : 'No',
    },
    {
        label: 'Metallic Level',
        value: plan.info.metallicLevel,
    },
    {
        label: 'Deductible (Single/Family)',
        value: `${plan.details.deductibleSingle ? '$' + plan.details.deductibleSingle : '--'}/${plan.details.deductibleFamily ? '$' + plan.details.deductibleFamily : '--' }`,
    },
    {
        label: 'Actuarial value',
        value: plan.info.actuarialValue?.toString() ?? '--',
    },
    {
        label: 'Co-Insurance',
        value: `${plan.details.coinsuranceMember ?? 0}% (Employee Cost)`,
    },
    {
        label: 'Deductible Non Network',
        value: `${plan.details.deductibleSingleNonNetwork ? '$' + plan.details.deductibleSingleNonNetwork : '--'}/${plan.details.deductibleFamilyNonNetwork ? '$' + plan.details.deductibleFamilyNonNetwork : '--'}`,
    },
    {
        label: 'Out-of-Pocket Max',
        value: `${plan.details.oopSingle ? '$' + plan.details.oopSingle : '--'}/${plan.details.oopFamily ? '$' + plan.details.oopFamily : '--'}`,
    },
    {
        label: 'OOP Max Non-Network',
        value: `${plan.details.oopSingleNonNetwork ? '$' + plan.details.oopSingleNonNetwork : '--'}/${plan.details.oopFamilyNonNetwork ? '$' + plan.details.oopFamilyNonNetwork : '--'}`,
    },
]);

export const getRxBenefits = (planDetails: PlanDetails): SummaryTableData[] => ([
    {
        label: 'Rx Deductible Single',
        value: `$${planDetails.rxDeductibleSingleValue ?? 0}`,
    },
    {
        label: 'Included with Medical Deductible',
        value: planDetails.rxMedicalDeductibleIntegration ? 'Yes' : 'No',
    },
    {
        label: 'Rx Deductible Family',
        value: `$${planDetails.rxDeductibleFamilyValue ?? 0}`
    },
    {
        label: 'Included with Medical Deductible',
        value: planDetails.rxMedicalDeductibleIntegration ? 'Yes' : 'No',
    },
]);

export const getAdditionalBenefitsInfo = (coverage: PlanCoverageDetails): SummaryTableData[] => ([
    {
        isHeader: true,
        label: 'Primary Care Physician',
        value: ``,
    },
    {
        isHeader: true,
        label: 'Specialist',
        value: ``,
    },
    {
        label: `${coverage.pcpValueType}`,
        value: `$${coverage.pcpValue ?? 0}`,
    },
    {
        label: `${coverage.specialistValueType}`,
        value: `$${coverage.specialistValue ?? 0}`,
    },
    {
        label: 'Deductible waivers?',
        value: coverage.pcpDedWaiver ? 'Yes' : 'No',
    },
    {
        label: 'Deductible waivers?',
        value: coverage.specialistDedWaiver ? 'Yes' : 'No',
    },
    {
        label: 'Waiver limit',
        value: `${coverage.pcpWaiverLimit ?? 0}`
    },
    {
        label: 'Waiver limit',
        value: `${coverage.specialistWaiverLimit ?? 0}`
    },
    { ...blankCell },
    { ...blankCell },
    {
        label: 'Preventive Care',
        value: `${coverage.preventiveCare ? '$' + coverage.preventiveCare : '--'}`,
    },
    {
        label: 'Age Out of Network Care',
        value: `${coverage.outOfNetworkCoverage ? 'Yes' : 'No'}`,
    },
    {
        label: 'Urgent Care',
        value: `${coverage.urgentCare ?? '--'}`,
    },
    {
        label: 'Emergency room',
        value: `${coverage.emergencyRoom ?? '--'}`,
    },
    {
        label: 'Ambulance',
        value: `${coverage.ambulance ?? '--'}`,
    },
    {
        label: 'Diagnostic Test',
        value: `${coverage.diagnosticTest ?? '--'}`,
    },
    {
        label: 'Inpatient Birth',
        value: `${coverage.inpatientBirth ?? '--'}`,
    },
    {
        label: 'Prenatal Care',
        value: `${coverage.prenatalCare ?? '--'}`,
    },
    {
        label: 'Postnatal Care',
        value: `${coverage.postnatalCare ?? '--'}`,
    },
    { ...blankCell },
    {
        label: 'Inpatient Facility',
        value: `${coverage.inpatientFacility ?? '--'}`,
    },
    {
        label: 'Outpatient Facility',
        value: `${coverage.outpatientFacility ?? '--'}`,
    },
    {
        label: 'Inpatient Physician',
        value: `${coverage.inpatientPhysician ?? '--'}`,
    },
    {
        label: 'Outpatient Physician',
        value: `${coverage.outpatientPhysician ?? '--'}`,
    },
    {
        label: 'Inpatient Mental Health',
        value: `${coverage.inpatientMentalHealth ?? '--'}`,
    },
    {
        label: 'Outpatient Mental Health',
        value: `${coverage.outpatientMentalHealth ?? '--'}`,
    },
    {
        label: 'Inpatient Substance Abuse',
        value: `${coverage.inpatientSubstanceAbuse ?? '--'}`,
    },
    {
        label: 'Outpatient Substance Abuse',
        value: `${coverage.outpatientSubstanceAbuse ?? '--'}`,
    },
    {
        label: 'Rehabilitation Services',
        value: `${coverage.rehabilitationServices ?? '--'}`,
    },
    {
        label: 'Skilled Nursing',
        value: `${coverage.skilledNursing ?? '--'}`,
    },
    {
        label: 'Home Health Care',
        value: `${coverage.homeHealthCare ?? '--'}`,
    },
    {
        label: 'Hospice',
        value: `${coverage.hospice ?? '--'}`,
    },
    {
        label: 'Chiropractic Services',
        value: `${coverage.chiropracticServices ?? '--'}`,
    },
    {
        label: 'Imaging',
        value: `${coverage.imaging ?? '--'}`,
    },
    {
        label: 'Imaging Center',
        value: `${coverage.imagingCenter ?? '--'}`,
    },
    {
        label: 'Imaging Physician',
        value: `${coverage.imagingPhysician ?? '--'}`,
    },
]);

export const getRates = (rates: Rates, hasTobacco: boolean): SummaryTableData[] => {
    if (rates.type === RateType.Composite) {
        return [
            {
                label: 'Type',
                value: `Composite`,
            },
            { ...blankCell },
            {
                label: 'Employee only',
                value: `$${rates.composite.single ?? 0}`,
            },
            {
                label: 'Employee & spouse',
                value: `$${rates.composite.singleAndSpouse ?? 0}`,
            },
            {
                label: 'Employee & child',
                value: `$${rates.composite.singleAndChildren ?? 0}`,
            },
            {
                label: 'Family',
                value: `$${rates.composite.family ?? 0}`,
            },
        ];
    }
    if (rates.type === RateType.AgeRate && rates.age.age21) {
        const summaryRates: SummaryTableData[] = Object.entries(rates.age)
            .slice(21)
            .map(([_key, value], index) => ({ label: `Age ${index + 21}`, value: `$${value}` }));
        summaryRates.unshift({
            label: 'Ages 0-20',
            value: `$${rates.age.age0}`,
        });
        if (rates.tobacco.age21 && hasTobacco) {
            summaryRates.unshift(...[
                {
                    label: 'Age rates',
                    value: '',
                    isHeader: true,
                },
                { ...blankHeader },
                { ...blankHeader },
            ]);
            summaryRates.push(...[
                { ...blankCell },
                { ...blankCell },
                { ...blankCell },
                { ...blankCell },
                { ...blankCell },
                {
                    label: 'Age tobacco rates',
                    value: '',
                    isHeader: true,
                },
                { ...blankHeader },
                { ...blankHeader },
            ]);
            summaryRates.push({
                label: 'Ages 0-20',
                value: `$${rates.tobacco.age21}`,
            });
            summaryRates.push(...Object.entries(rates.tobacco)
                .slice(21)
                .map(([_key, value], index) => ({ label: `Age ${index + 21}`, value: `$${value}` })))
            summaryRates.push({ ...blankCell }, { ...blankCell })
        }
        return summaryRates;
    }
    return [];
}

export const getCustomPlanSummary = (info: PlanInfo,  details: PlanDetails, type: RateType): SummaryTableData[] => ([
    {
        label: 'Network',
        value: info.networkName ?? '--',
    },
    {
        label: 'Co-Insurance',
        value: `${details.coinsuranceMember ?? 0}% (Employee Cost)`,
    },
    {
        label: 'HSA Qualified',
        value: info.hsaEligible ? 'Yes' : 'No',
    },
    {
        label: 'Out-of-Pocket Max',
        value: `${details.oopSingle ? '$' + details.oopSingle : '--'}/${details.oopFamily ? '$' + details.oopFamily : '--'}`,
    },
    {
        label: 'Deductible Non Network',
        value: `${details.deductibleSingleNonNetwork ? '$' + details.deductibleSingleNonNetwork : '--'}/${details.deductibleFamilyNonNetwork ? '$' + details.deductibleFamilyNonNetwork : '--'}`,
    },
    {
        label: 'Contribution type',
        value: type === 'composite' ? 'Composite' : 'Age rated',
    },
]);