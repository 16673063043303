/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { PlanOverview } from '@besc/agency-custom-plans';
import { getAdditionalBenefitsInfo, getPlanDetailsInfo, getRates, getRxBenefits } from '../../utils/plan-details';
import { SummaryTableData } from '@besc/ui';
import { Employer } from '@besc/employer';
import { PlanFacade } from '../../services/plan.facade';
import { RateType } from '../../interfaces';
import { Quote } from '@besc/quote';

@Component({
    selector: 'besc-plan-full-details-modal',
    templateUrl: './plan-full-details-modal.component.html',
    styleUrls: ['./plan-full-details-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanFullDetailsModalComponent implements OnInit {
    @Input() planOverview: PlanOverview;
    @Input() plan: Quote;
    @Input() isCustom: boolean;
    @Input() employer: Employer;
    @Input() planId: string;
    @Input() effectiveDate: string;
    @Input() zipCode: string;

    planDetailsOpenState$ = new BehaviorSubject<boolean>(true);
    rxBenefitsOpenState$ = new BehaviorSubject<boolean>(false);
    additionalBenefitsOpenState$ = new BehaviorSubject<boolean>(false);
    planRatesOpenState$ = new BehaviorSubject<boolean>(false);

    planDetails$ = new BehaviorSubject<SummaryTableData[]>([]);
    rxBenefits$ = new BehaviorSubject<SummaryTableData[]>([]);
    additionalBenefits$ = new BehaviorSubject<SummaryTableData[]>([]);
    rates$ = new BehaviorSubject<SummaryTableData[]>([]);

    rateType: RateType;

    constructor(
        private _modal: NgbActiveModal,
        private _planFacade: PlanFacade,
    ) {}

    ngOnInit(): void {
        this._getPlanDetails();
    }

    private _getPlanDetails(): void {
        if (this.isCustom) {
            this._getCustomPlanDetails();
        } else {
            this._getStandardPlanDetails();
        }
    }

    private _getCustomPlanDetails(): void {
        this._planFacade.getAgencyCustomPlan(this.planId).pipe(
            tap(customPlan => {
                this.planDetails$.next(getPlanDetailsInfo({
                    info: customPlan.plan.info,
                    effectiveDate: customPlan.plan.effectiveDate,
                    expirationDate: customPlan.plan.expirationDate,
                    details: customPlan.plan.details,
                    editable: false,
                }));
                this.rxBenefits$.next(getRxBenefits(customPlan.plan.details));
                this.additionalBenefits$.next(getAdditionalBenefitsInfo(customPlan.plan.coverage));
            })
        ).subscribe();

        this._planFacade.getAgencyCustomPlanEmployers(this.planId)
            .pipe(
                tap(customPlanQuotedEmployers => {
                    const quotedEmployer = customPlanQuotedEmployers.employers.find(employer => employer.employerId === this.employer.id);
                    this.rateType = quotedEmployer ? quotedEmployer.rates.rates.type : RateType.Composite;
                    this.rates$.next(quotedEmployer ? getRates(quotedEmployer.rates.rates, quotedEmployer.rates.rates.tobacco.age21 > 0) : []);
                }),
            )
            .subscribe();
    }

    private _getStandardPlanDetails(): void {
        this._planFacade.get(this.plan.id, this.zipCode, this.effectiveDate, this.employer.id).pipe(
            tap(fullPlan => {
                this.planDetails$.next(getPlanDetailsInfo({
                    info: fullPlan.info,
                    effectiveDate: fullPlan.effectiveDate,
                    expirationDate: fullPlan.expirationDate,
                    details: fullPlan.details,
                    editable: false,
                }));
                this.rxBenefits$.next(getRxBenefits(fullPlan.details));
                this.additionalBenefits$.next(getAdditionalBenefitsInfo(fullPlan.coverage));
                this.rates$.next(
                    getRates(
                        fullPlan.planRates.rates,
                        fullPlan.planRates.rates.tobacco.age21 > 0
                    )
                );
            })
        ).subscribe();
    }

    close(): void {
        this._modal.close();
    }
}
